*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

a{
  text-decoration: none;
  color: inherit;
}

input{
  font-size: 1.2em;
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid black;
  outline: none;
}
input:focus{
  border-color: grey;
}

select{
  font-size: 1.2em;
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid black;
  outline: none;
  background-color: transparent;  
}
select:focus{
  border-color: grey;
}

h1{
  font-size: 4.5rem;
}
h2{
  font-size: 3.5rem;
}
h3{
  font-size: 2.5rem;
}

table,
th,
td {
  border: 1px solid;
}

table {
  width: 100%;
  margin: 0 auto;
  display: block;
  overflow-x: auto;
  border-spacing: 0;
  border-radius: 15px;
  table-layout: fixed;
    word-wrap:break-word;
}

/* tbody {
  white-space: nowrap;
} */

th,
td {
  padding: 5px 10px;
  border-top-width: 0;
  border-left-width: 0;
  overflow-wrap: break-word;
  max-width: 200px;
}

th {
  position: sticky;
  top: 0;
  background: #fff;
  vertical-align: bottom;
}

th:last-child,
td:last-child {
  border-right-width: 0;
}

tr:last-child td {
  border-bottom-width: 0;
}

.sectionTitle{
  text-align: center;
  margin-bottom: 2rem;
}

.Form{
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.Form > div{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.Form > div p{
  padding-bottom: 0.5rem;
}

.Form .FormBtn{
  width: 100%;
  font-size: 1.2em;
  margin-top: 4rem;
  padding: 1rem;
  border-radius: 10rem;
  outline: none;
  border: none;
  cursor: pointer;
}
/* .Form .loginBtn:hover{
  background-color: red;
} */

.active{
  font-size: 2rem;
  text-decoration: underline;
  color: blueviolet;
}

.container{
  max-width: 800px;
  margin: auto;
  margin-top: 3rem;
  padding: 1rem;
}
